import React from 'react';
import Layout from '../layout';
import { Box, Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Stamp from '../components/Stamp/Stamp';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import PromoCTA from '../components/PromoCTA/PromoCTA';
import WorkSection from '../components/WorkSection';
import { darkTheme } from '../theme';
import JsxSeo from '../components/SEO/JsxSEO';
import { StaticImage } from 'gatsby-plugin-image';
import { getBlogPosts } from '../middleware/firestore/getBlogPosts';
import { deserialize } from 'react-serialize';
import Button from '@material-ui/core/Button';
import Link from 'gatsby-link';
import magicImage from '../../static/images/magic1.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(12),
    },
  },
  title: {
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  leadContainer: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
  },
  stampWrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '16rem',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    },
  },
  stamp: {
    position: 'absolute',
    bottom: theme.spacing(0),
    right: theme.spacing(0),

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  combo: {
    marginTop: '2rem',
  },
  illustration: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Index({ location }) {
  const classes = useStyles();

  return (
    <Layout>
      <JsxSeo title={'SpiceFactory'} location={location} />

      <Container maxWidth={false} className={classes.hero}>
        <Typography className={classes.title} variant="h1">
          We build super spicy products from scratch to product-market fit.
        </Typography>
        <Box flex={1} display="flex">
          <Grid container>
            <Grid item lg={5} sm={8}>
              <Button variant="contained" color="primary" component={Link} to="/contact-us">
                Let’s build your product
              </Button>
            </Grid>
            <Grid item lg={7} sm={4} xs={12}>
              <Box className={classes.stampWrapper}>
                <div className={classes.stamp}>
                  <Stamp />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container className={classes.leadContainer}>
        <Typography align="center" variant="subtitle1" component="p">
          Next gen product development spiced up with holistic approach & customer-centricity.
        </Typography>
      </Container>

      <ThemeProvider theme={darkTheme}>
        <WorkSection />
      </ThemeProvider>

      <Container className={classes.section} component="section">
        <Grid container alignItems="center">
          <Grid item md={6}>
            <Typography variant="h2" gutterBottom>
              Are we a good combo?
            </Typography>
            <Grid container direction="column">
              <Grid item>
                <Typography>
                  We work with startups, established businesses and enterprises, as well as products for in-house
                  purposes.
                </Typography>
              </Grid>
              <Grid item className={classes.combo}>
                <Typography>
                  If you need a cross functional, experienced, trustworthy partner to help scale your business, let's
                  talk.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} className={classes.illustration}>
            <img width={300} src={magicImage} alt="illustration of a hand and a star above it" loading="lazy" />
          </Grid>
        </Grid>
      </Container>

      <PromoCTA text="Have a project in mind?" actionText="Get in touch" to="/contact-us" />
    </Layout>
  );
}

export default Index;
