import Link from 'gatsby-link';
import AddIcon from '@material-ui/icons/Add';
import { Link as MuiLink, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.button.fontFamily,
  },
  icon: {
    marginLeft: '32px',
  },
}));

const UnderlineButton = ({ buttonText, to }) => {
  const classes = useStyles();

  return (
    <MuiLink className={classes.link} variant="subtitle2" color="inherit" underline="always" component={Link} to={to}>
      {buttonText} <AddIcon className={classes.icon} fontSize="inherit" color="primary" />
    </MuiLink>
  );
};

export default UnderlineButton;
