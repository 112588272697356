import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { Container, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UnderlineButton from './UnderlineButton/UndrelineButton';
import { ContentCard } from './ContentCard/ContentCard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(20, 0),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    background: theme.palette.background.default,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const WorkSection = () => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        query WorksQuery {
          allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(/work)/" } }, limit: 3) {
            edges {
              node {
                id
                frontmatter {
                  title
                  description
                  featuredImage {
                    childImageSharp {
                      gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                  }
                  featuredImageTitle
                  hidden
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Box component="section" className={classes.root}>
          <Container maxWidth={false}>
            <div className={classes.header}>
              <Typography variant="h2" gutterBottom>
                A Taste of Our Work
              </Typography>
              <UnderlineButton buttonText="All work" to="/work" />
            </div>
            <Box mt={10} display="flex">
              <Grid container spacing={4}>
                {data.allMarkdownRemark.edges
                  .filter(item => !item.node.frontmatter.hidden)
                  .map((item, index) => (
                    <Grid item md={4} key={index}>
                      <ContentCard
                        cover={getImage(item.node.frontmatter.featuredImage)}
                        title={item.node.frontmatter.title}
                        to={`/work${item.node.fields.slug}`}
                        imageTitle={item.node.frontmatter.featuredImageTitle}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    />
  );
};
export default WorkSection;
