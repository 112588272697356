import * as React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../data/SiteConfig';
import fallbackimage from '../../../static/images/seo/abstract.jpg';

function JsxSeo({
  location,
  title,
  description,
  image,
}: {
  location: string;
  title: string;
  description?: string;
  image?: string;
}) {
  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      logo: config.siteLogo,
    },
  ];

  const defaultDescription =
    'We are a product development studio that combines UX, design and engineering expertise to build your product-market fit.';
  const defaultImage = `${config.siteUrl}${fallbackimage}`;
  const defaultTitle = config.siteTitle;

  return (
    <Helmet title={title ? `${title} | ${config.siteTitleShort}` : `${config.siteTitleShort}`}>
      {/* Warm up google fonts*/}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      {/* General tags */}
      <meta name="description" content={description ? description : defaultDescription} />
      <meta name="image" content={image ? `${config.siteUrl}${image}` : defaultImage} />

      {/*Structured data*/}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={`${config.siteUrl}${location.pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title ? title : defaultTitle} />
      <meta property="og:description" content={description ? description : defaultDescription} />
      <meta property="og:image" content={image ? `${config.siteUrl}${image}` : defaultImage} />
      <meta property="fb:app_id" content={config.siteFBAppID} />

      {/* Twitter Card tags */}
      <meta name="twitter:site" content="@SpiceFctry" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@SpiceFctry" />
      <meta name="twitter:title" content={title ? title : defaultTitle} />
      <meta name="twitter:description" content={description ? description : defaultDescription} />
      <meta name="twitter:image" content={image ? `${config.siteUrl}${image}` : defaultImage} />
    </Helmet>
  );
}

export default JsxSeo;
