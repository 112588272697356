import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnderlineButton from '../UnderlineButton/UndrelineButton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    backgroundColor: theme.palette.redPepper,
    color: theme.palette.getContrastText(theme.palette.redPepper),
  },
}));

const PromoCTA = ({ text, actionText, to }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.section} component="section">
      <Typography variant="h2" gutterBottom align="center">
        {text}
      </Typography>
      <Box display="flex" justifyContent="center">
        <UnderlineButton buttonText={actionText} to={to} />
      </Box>
    </Container>
  );
};

export default PromoCTA;
