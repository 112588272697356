import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { makeStyles } from '@material-ui/core/styles';
import stampText from './stamp_text.svg';

const useStyles = makeStyles(theme => ({
  stamp: {
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    width: '16rem',
    height: '16rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '12rem',
      height: '12rem',
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  text: {
    position: 'absolute',
    width: '85%',
    animation: `$rotate 40s linear infinite`,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  icon: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
  },
}));

const Stamp = () => {
  const classes = useStyles();

  return (
    <div className={classes.stamp}>
      <img
        src={stampText}
        alt="Guaranteed Hot and Spicy Flavours Since 2014."
        loading="eager"
        width={300}
        className={classes.text}
      />
      <ArrowDownwardIcon className={classes.icon} fontSize="large" />
    </div>
  );
};

export default Stamp;
