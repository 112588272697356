import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  unstyledLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  cover: {
    maxWidth: '100%',
    objectFit: 'cover',
    marginLeft: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  title: {
    maxWidth: '95%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
}));

export const ContentCard = ({
  to,
  cover,
  category,
  title,
  subtitle,
  imageTitle,
}: {
  to: string;
  cover: IGatsbyImageData;
  category?: string;
  title: string;
  subtitle?: string;
  imageTitle?: string;
}) => {
  const classes = useStyles();

  return (
    <article>
      <Link to={to} className={classes.unstyledLink}>
        <Box>
          {cover && (
            <GatsbyImage
              aria-describedby={imageTitle}
              image={cover}
              alt={imageTitle}
              className={classes.cover}
              loading="lazy"
            />
          )}
        </Box>
        <Box mt={4}>
          <Box>
            {category && <Typography color="primary">{category}</Typography>}
            <Typography className={classes.title} variant="h3" gutterBottom>
              {title}
            </Typography>
          </Box>

          {subtitle && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
              <AddIcon color="primary" />
            </Box>
          )}
        </Box>
      </Link>
    </article>
  );
};
